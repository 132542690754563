import React from 'react';
import { connect } from 'react-redux';
import Loading from 'components/shared-components/Loading';
import HeaderNav from 'components/layout-components/HeaderNav';
import Footer from 'components/layout-components/Footer';
import PoliticasView from 'views/politicas-view';
import {
  Layout,
} from "antd";

import { useThemeSwitcher } from "react-css-theme-switcher";
export const PoliticaLayout = ({ navCollapsed, navType, location }) => {
  const { Content } = Layout;

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }


  return (
    <Layout>
      <HeaderNav hiddenProfile={true}/>
      <Layout className="app-container">
        <Layout className="app-layout">
          <Content>
          <div className={`app-content layout-top-nav`}>
              <PoliticasView />
            </div>
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(PoliticaLayout));