import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`/politica/politica-privacidade`} component={lazy(() => import(`./politica-privacidade`))} />
        <Route path={`/politica/termos-condicao`} component={lazy(() => import(`./termos-condicao`))} />
        <Redirect from={`/politica`} to={`/politica/politica-privacidade`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);