import {
  HomeOutlined,
  IdcardOutlined,
  UnorderedListOutlined,
  FileOutlined,
  FileTextOutlined,
  UserOutlined,
  FileDoneOutlined,
  MessageOutlined,
  SettingOutlined,
  SafetyOutlined,
  UsergroupAddOutlined,
  ControlOutlined,
  SnippetsOutlined,
  CompassOutlined,
  TeamOutlined,
  MailOutlined
} from '@ant-design/icons';
import store from '../redux/store';
import { APP_PREFIX_PATH, ADM_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNotAuthNavTree = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    title: 'login',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'fale-conosco',
    path: `${AUTH_PREFIX_PATH}/fale-conosco`,
    title: 'fale-conosco',
    icon: MailOutlined,
    breadcrumb: false,
    submenu: []
  }
]

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'dados-cadastrais',
    path: `${APP_PREFIX_PATH}/dados-cadastrais`,
    title: 'dados-cadastrais',
    icon: IdcardOutlined,
    breadcrumb: false,
    submenu: []
  }
]

const dashBoardAdmNavTree = [
  {
    key: 'home',
    path: `${ADM_PREFIX_PATH}/home`,
    title: 'inicio',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'processos',
    path: `${ADM_PREFIX_PATH}/processos`,
    title: 'processos',
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [],
    rules: 1
  },
  {
    key: 'base-dados',
    path: `${ADM_PREFIX_PATH}/pescadores`,
    title: 'pescadores',
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
    rules: 2
  },
  {
    key: 'adesoes',
    path: `${ADM_PREFIX_PATH}/adesoes`,
    title: 'adesoes',
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: [],
    rules: 3
  },
  {
    key: 'fale-conosco',
    path: `${ADM_PREFIX_PATH}/fale-conosco`,
    title: 'fale-conosco',
    icon: MessageOutlined,
    breadcrumb: false,
    submenu: [],
    rules: 4
  },
  {
    key: 'relatorio',
    path: `${ADM_PREFIX_PATH}/relatorio`,
    title: 'relatorios',
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'base-pescadores',
        path: `${ADM_PREFIX_PATH}/relatorio/base-pescadores`,
        title: 'base-pescadores',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
        rules: 8
      },
      {
        key: 'base-processos',
        path: `${ADM_PREFIX_PATH}/relatorio/base-processos`,
        title: 'base-processos',
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
        rules: 9
      },
      {
        key: 'base-adesoes',
        path: `${ADM_PREFIX_PATH}/relatorio/base-adesoes`,
        title: 'base-adesoes',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
        rules: 10
      },
      {
        key: 'documentos',
        path: `${ADM_PREFIX_PATH}/relatorio/documentos`,
        title: 'documentos',
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
        rules: 11
      },
    ],
    rules: [8, 9, 10, 11]
  },
  {
    key: 'seguranca',
    title: 'seguranca',
    icon: SafetyOutlined,
    breadcrumb: false,
    submenu: [ 
      {
        key: 'usuarios',
        path: `${ADM_PREFIX_PATH}/usuarios`,
        title: 'usuarios',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'grupos',
        path: `${ADM_PREFIX_PATH}/grupos`,
        title: 'grupos',
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'funcoes',
        path: `${ADM_PREFIX_PATH}/funcoes`,
        title: 'funcoes',
        icon: ControlOutlined,
        breadcrumb: false,
        submenu: []
      },
    ],
    rules: 7
  },
  {
    key: 'configuracoes',
    title: 'configuracoes',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [ 
      {
        key: 'advogados',
        path: `${ADM_PREFIX_PATH}/configuracoes/advogados`,
        title: 'advogados',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'tipos-de-documento',
        path: `${ADM_PREFIX_PATH}/configuracoes/tipos-de-documento`,
        title: 'tipos-de-documento',
        icon: SnippetsOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'tipos-de-pescado',
        path: `${ADM_PREFIX_PATH}/configuracoes/tipos-de-pescado`,
        title: 'tipos-de-pescado',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'locais-de-pesca',
        path: `${ADM_PREFIX_PATH}/configuracoes/locais-de-pesca`,
        title: 'locais-de-pesca',
        icon: CompassOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'Tipos de Indenização',
        path: `${ADM_PREFIX_PATH}/configuracoes/tipos-de-indenizacao`,
        title: 'tipos-de-indenizacao',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'Politicas de Privacidade',
        path: `${ADM_PREFIX_PATH}/configuracoes/politica-privacidade`,
        title: 'politica-privacidade',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'Termos e Condições',
        path: `${ADM_PREFIX_PATH}/configuracoes/termos-condicao`,
        title: 'termos-condicao',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: []
      },
    ],
    rules: 6
  },
]

const { auth } = store.getState();

let navigationConfig = [];
if(auth.admin) {
  navigationConfig = [
    ...dashBoardAdmNavTree
  ]
} else if(auth.token) {
  navigationConfig = [
    ...dashBoardNavTree
  ]
} else {
  navigationConfig = [
    ...dashBoardNotAuthNavTree
  ]
}

export default navigationConfig;
