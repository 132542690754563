import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { ADM_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AdmViews = () => {

  function PrivateRoute ({component: Component, rules, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) => { 
          const userFunctions = JSON.parse(localStorage.getItem('usuario_grupofuncoessistema'));
          if(userFunctions.includes(rules)) {
            return <Component {...props} />
          } else {
            return <Redirect to={{pathname: `${AUTH_PREFIX_PATH}`, state: {from: props.location}}} />
          }
        }  
        }
      />
    )
  }

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${ADM_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />

        <PrivateRoute path={`${ADM_PREFIX_PATH}/processos`} exact component={lazy(() => import(`./processos`))} rules={1}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/processos/create`} exact component={lazy(() => import(`./processos/create`))} rules={1}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/processos/:id`} component={lazy(() => import(`./processos/edit`))} rules={1}/>
        
        <PrivateRoute path={`${ADM_PREFIX_PATH}/pescadores`} exact component={lazy(() => import(`./pescadores`))} rules={2}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/pescadores/:id`} component={lazy(() => import(`./pescadores/edit`))} rules={2}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/adesoes`} exact component={lazy(() => import(`./adesoes`))} rules={3}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/adesoes/:id`} component={lazy(() => import(`./adesoes/edit`))} rules={3}/>
        
        <PrivateRoute path={`${ADM_PREFIX_PATH}/fale-conosco`} exact component={lazy(() => import(`./fale-conosco`))} rules={4}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/fale-conosco/:id`} component={lazy(() => import(`./fale-conosco/edit`))} rules={4}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/relatorio/base-pescadores`} exact component={lazy(() => import(`./relatorios/pescadores`))} rules={8}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/relatorio/base-processos`} exact component={lazy(() => import(`./relatorios/processos`))} rules={9}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/relatorio/base-adesoes`} exact component={lazy(() => import(`./relatorios/adesoes`))} rules={10}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/relatorio/documentos`} exact component={lazy(() => import(`./relatorios/documentos`))} rules={11}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-documento`} exact component={lazy(() => import(`./configuracoes/tipos-de-documento`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-documento/criar`} exact component={lazy(() => import(`./configuracoes/tipos-de-documento/create`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-documento/:id`} component={lazy(() => import(`./configuracoes/tipos-de-documento/edit`))} rules={6}/>
        
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/advogados`} exact component={lazy(() => import(`./configuracoes/advogados`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/advogados/criar`} exact component={lazy(() => import(`./configuracoes/advogados/create`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/advogados/:id`} component={lazy(() => import(`./configuracoes/advogados/edit`))} rules={6}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-pescado`} exact component={lazy(() => import(`./configuracoes/tipos-de-pescado`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-pescado/criar`} exact component={lazy(() => import(`./configuracoes/tipos-de-pescado/create`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-pescado/:id`} component={lazy(() => import(`./configuracoes/tipos-de-pescado/edit`))} rules={6}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/locais-de-pesca`} exact component={lazy(() => import(`./configuracoes/locais-de-pesca`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/locais-de-pesca/criar`} exact component={lazy(() => import(`./configuracoes/locais-de-pesca/create`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/locais-de-pesca/:id`} component={lazy(() => import(`./configuracoes/locais-de-pesca/edit`))} rules={6}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-indenizacao`} exact component={lazy(() => import(`./configuracoes/tipos-de-indenizacao`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-indenizacao/criar`} exact component={lazy(() => import(`./configuracoes/tipos-de-indenizacao/create`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/tipos-de-indenizacao/:id`} component={lazy(() => import(`./configuracoes/tipos-de-indenizacao/edit`))} rules={6}/>
        
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/politica-privacidade`} exact component={lazy(() => import(`./configuracoes/politica-privacidade`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/politica-privacidade/criar`} exact component={lazy(() => import(`./configuracoes/politica-privacidade/create`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/politica-privacidade/:id`} component={lazy(() => import(`./configuracoes/politica-privacidade/edit`))} rules={6}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/termos-condicao`} exact component={lazy(() => import(`./configuracoes/termos-condicao`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/termos-condicao/criar`} exact component={lazy(() => import(`./configuracoes/termos-condicao/create`))} rules={6}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/configuracoes/termos-condicao/:id`} component={lazy(() => import(`./configuracoes/termos-condicao/edit`))} rules={6}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/usuarios`} exact component={lazy(() => import(`./usuarios`))} rules={7}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/usuarios/create`} exact component={lazy(() => import(`./usuarios/create`))} rules={7}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/usuarios/:id`} component={lazy(() => import(`./usuarios/edit`))} rules={7}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/grupos`} exact component={lazy(() => import(`./grupos`))} rules={7}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/grupos/criar`} exact component={lazy(() => import(`./grupos/create`))} rules={7}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/grupos/:id`} exact component={lazy(() => import(`./grupos/edit`))} rules={7}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/grupos/:id/funcoes`} component={lazy(() => import(`./grupos/funcoes`))} rules={7}/>

        <PrivateRoute path={`${ADM_PREFIX_PATH}/funcoes`} exact component={lazy(() => import(`./funcoes`))} rules={7}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/funcoes/criar`} exact component={lazy(() => import(`./funcoes/create`))} rules={7}/>
        <PrivateRoute path={`${ADM_PREFIX_PATH}/funcoes/:id`} component={lazy(() => import(`./funcoes/edit`))} rules={7}/>

        <Redirect from={`${ADM_PREFIX_PATH}`} to={`${ADM_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AdmViews);