import React from 'react'
import { connect } from 'react-redux';
import { NAV_TYPE_TOP } from 'constants/ThemeConstant';
import utils from 'utils'
import MenuContent from './MenuContent'

export const TopNav = ({topNavColor, localization = true, title}) => {
	const props = { topNavColor, localization }
	return (
		<div className={`top-nav ${utils.getColorContrast(topNavColor)}`} style={{backgroundColor: topNavColor}}>
			<div className="top-nav-wrapper px-4 h-100">
				<div className="d-flex justify-content-between align-items-center h-100">
					<div className="d-flex align-items-center">
						<h3 className={'text-white m-0'}>{ title }</h3>
					</div>
					<MenuContent
						type={NAV_TYPE_TOP} 
						{...props}
					/>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ theme }) => {
  const { topNavColor } =  theme;
  return { topNavColor }
};

export default connect(mapStateToProps)(TopNav);
