import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Modal } from 'antd';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = (props) => {
  const { isMobile } = props;

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/cadastro`} component={lazy(() => import(`./cadastro`))} />
        <Route path={`${APP_PREFIX_PATH}/dados-cadastrais`} component={lazy(() => import(`./dados-cadastrais`))} />
        <Route path={`${APP_PREFIX_PATH}/confirmacao`} component={lazy(() => import(`./confirmacao`))} />
        <Route path={`${APP_PREFIX_PATH}/alterar-senha`} component={lazy(() => import(`./alterar-senha`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);