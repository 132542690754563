import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 540000 // 9 minutos
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)
	
  if (jwtToken) {
	config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	config.headers.Authorization = jwtToken;
  }

//   if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
// 		history.push(ENTRY_ROUTE)
// 		window.location.reload();
//   }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Erro! Caso persistir, contate o suporte'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, async (error) => {

	let notificationParam = {
		message: 'Erro desconhecido! Contate o suporte!'
	}
	
	if(error.response) {
		// Remove token and redirect	
		if (error.response.status === 400) {
			notificationParam.message = 'Erro!'

			if (error.request.responseType === 'blob') { // caso o response espere um arquivo
				let responseObj = await error.response.data.text();
				let responseJson = JSON.parse(responseObj);
				notificationParam.description = responseJson.error;
			} else { // caso nao espere avisa normalmente
				notificationParam.description = error.response.data.error;
			}
		}

		if (error.response.status === 403 || error.response.status === 401) {
			notificationParam.message = 'Erro de autenticação!'
			notificationParam.description = error.response.data.error
			localStorage.removeItem(AUTH_TOKEN)
			history.push(ENTRY_ROUTE)
			window.location.reload();
		}

		if (error.response.status === 404) {
			notificationParam.message = 'Não encontrado'
		}

		if (error.response.status === 500) {
			notificationParam.message = 'Ops! Houve um erro interno.'
		}
		
		if (error.response.status === 508) {
			notificationParam.message = 'Ops! Seu pedido demorou muito.'
		}
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service