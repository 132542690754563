import fetch from 'auth/FetchInterceptor'

const GrupoService = {}

// GRUPOS 
GrupoService.getAllGrupos = function () {
    return fetch({
        url: '/gruposistema',
        method: 'get'
    })
}

GrupoService.getGrupo = function (id) {
    return fetch({
        url: `/gruposistema/${id}`,
        method: 'get'
    })
}
  
GrupoService.createGrupo = function (data) {
    return fetch({
        url: '/gruposistema',
        method: 'post',
        data: data
    })
}

GrupoService.updateGrupo = function (data) {
    return fetch({
        url: `/gruposistema/${data.id}`,
        method: 'put',
        data: data
    })
}

GrupoService.deleteGrupo = function (id) {
    return fetch({
        url: `/gruposistema/${id}`,
        method: 'delete'
    })
}

export default GrupoService