import React from 'react'
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {
	return (
		<footer className="footer">
			<span><span className="font-weight-semibold">{`${APP_NAME}`}</span> &copy; {`${new Date().getFullYear()}`}. Desenvolvido por: <a target="_blank" href="https://www.clickativo.com.br/?utm_source=site_cliente&utm_medium=site&utm_campaign=jurispesca">Clickativo</a>.</span>
			<div style={{cursor: 'pointer'}}>
				<a className="text-gray" target="_blank" href="/politica/termos-condicao">Termos e Condições</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" target="_blank" href="/politica/politica-privacidade">Politicas de Privacidade</a>
			</div>
		</footer>
	)
}

